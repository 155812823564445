<template>
	<div class="row">
		<div class="col-12">
			<h2>{{ $t('invoice.charge_account_supplier') }}</h2>
		</div>

		<div class="col-7">
            <iframe :src="invoice_supplier.suppliersocr_pdf_url" height="1000px" width="100%" style="position:relative"></iframe>
		</div>
		<div v-if="loading" class="col-5 row">
			<LoadingSpinner class="col-12" />
		</div>
		<div v-else class="col-5">
			<div v-for="(account, index) in charge_accounts" class="row border-2 py-2 mt-2" :key="index">
				<div class="col-12 row p-0">
					<div class="col-auto ml-auto p-0">
						<button @click="deleteCharge(index)" class="btn btn-secondary">
	                        <font-awesome-icon :icon="['fal', 'trash-alt']"/>
	                    </button>
					</div>
				</div>
                <div class="col-8">
                    <div class="form-group">
                        <label for="ischargeaccount_accountingaccount" class="col-form-label">{{ $t("compta.form.compte") }} *</label>
                        <e-select
                            v-model="account.ischargeaccount_accountingaccount"
                            id="ischargeaccount_accountingaccount"
                            track-by="id"
                            label="name"
                            :placeholder="labelTitleCompteComptable"
                            :deselectLabel="deselectLabel"
                            :selectedLabel="selectedLabel"
                            :selectLabel="enter_to_select"
                            :options="comptes_comptables"
                            :searchable="true"
                            :show-labels="false"
                            :allow-empty="false"
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="ischargeaccount_amount" class="col-form-label">{{ $t("invoice.amount") }} *</label>
                        <div class="input-group">
	                        <input type="text" id="ischargeaccount_amount" class="form-control" v-model="account.ischargeaccount_amount" @change="checkAmount" required>
							<div class="input-group-append">
								<div class="input-group-text">{{ invoice_supplier.suppliersocr_currency }}</div>
							</div>
						</div>
                 	</div>
                </div>
                <div class="col-8">
                    <div class="form-group">
                        <label for="ischargeaccount_vataccount" class="col-form-label">{{ $t("compta.form.vat_compte_comptable") }} *</label>
                        <e-select
                            v-model="account.ischargeaccount_vataccount"
                            id="ischargeaccount_vataccount"
                            track-by="id"
                            label="name"
                            :placeholder="labelTitleCompteComptable"
                            :deselectLabel="deselectLabel"
                            :selectedLabel="selectedLabel"
                            :selectLabel="enter_to_select"
                            :options="comptes_comptables_vat"
                            :searchable="true"
                            :show-labels="false"
                            :allow-empty="false"
                        >
                            <template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                        </e-select>
                    </div>
                </div>
                <div class="col-4">
                    <div class="form-group">
                        <label for="ischargeaccount_vatamount" class="col-form-label">{{ $t("invoice.vat_amount") }} *</label>
                        <div class="input-group">
	                        <input type="text" id="ischargeaccount_vatamount" class="form-control" v-model="account.ischargeaccount_vatamount" @change="checkVatAmount" required>
							<div class="input-group-append">
								<div class="input-group-text">{{ invoice_supplier.suppliersocr_currency }}</div>
							</div>
						</div>
                 	</div>
                 </div>

                <div class="col-12">
                	<label for="ischargeaccount_horse" class="col-form-label">{{ $t("invoice.horse_nom") }}</label>
                	<SearchHorse :horse_selected.sync="account.ischargeaccount_horse" :preselected="account.ischargeaccount_horse" />
                </div>
			</div>

			<div class="row border-2 mt-2">
				<b-button variant="secondary" class="w-100" @click="addChargeAccount">
	    			{{ $t('global.ajouter') }} <font-awesome-icon :icon="['fal', 'plus']"/>
	            </b-button>
			</div>

			<div class="mt-2">
				<ErrorAlert v-if="error_sum_amount" messageI18n="invoice.supplier_error_amount" />
				<ErrorAlert v-if="error_sum_vat" messageI18n="invoice.supplier_error_vat_amount" />
			</div>

	        <div class="form-group mt-3 text-center">
	            <button @click="save" class="btn btn-primary" :disabled="error_sum_amount || error_sum_vat">{{ $t('global.enregistrer') }} <font-awesome-icon v-if="processing" :icon="['fal', 'spinner']" spin /><font-awesome-icon v-else :icon="['far', 'save']" /></button>
	            <b-button class="ml-2" v-if="open_charge" variant="secondary" @click.prevent="skip"><font-awesome-icon :icon="['fal', 'times']" /> {{ $t('acte.trigger.ignorer') }}</b-button>
	        </div>
		</div>
	</div>
</template>

<script type="text/javascript">
    import Accounting from "@/mixins/Accounting.js"
	import Invoice from "@/mixins/Invoice.js"
	import Shutter from "@/mixins/Shutter.js"
	import ShutterInvoice from "@/mixins/shutters-manager/Invoice.js"
	import cloneDeep from 'lodash/cloneDeep'

	export default {
		name: 'AddChargeAccount',
		mixins: [Accounting, Invoice, Shutter, ShutterInvoice],
		props: ['invoice_supplier', 'open_charge'],
		data () {
			return {
                deselectLabel: this.getTrad("global.press_enter_to_remove"),
                selectedLabel: this.getTrad("global.selected_label"),
                enter_to_select: this.getTrad("global.press_enter_to_select"),
                labelTitleCompteComptable: this.getTrad("compta.search_accounting_account"),
                loading: false,
				processing: false,
				charge_accounts: [],
				default_account: {
					ischargeaccount_accountingaccount: null,
					ischargeaccount_vataccount: null,
					ischargeaccount_horse: null,
					ischargeaccount_amount: 0,
					ischargeaccount_vatamount: 0
				},
				comptes_comptables: [],
				comptes_comptables_vat: [],
				error_sum_amount: false,
				error_sum_vat: false,
				comptes_most_used: {}
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				this.loading = true
                if(Object.keys(this.comptes_comptables).length == 0) {
                    let comptes_comptables = await this.loadAccountingAccounts(this.invoice_supplier.accouting_plan.accountingplan_id)
                    comptes_comptables = comptes_comptables.filter(cc => cc.accountingaccount_supplier)

                    if(comptes_comptables) {
                        for(let i = 0; i < comptes_comptables.length; i++) {
							if(comptes_comptables[i].accountingaccount_vat == null) {
								this.comptes_comptables.push({
                                    name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
                                    id: comptes_comptables[i].accountingaccount_id 
                                })
							}
                            else
                            {
                                this.comptes_comptables_vat.push({
                                    name: comptes_comptables[i].accountingaccount_number + " ("+ comptes_comptables[i].accountingaccount_label +")", 
                                    id: comptes_comptables[i].accountingaccount_id,
                                    vat: comptes_comptables[i].accountingaccount_vat,
                                    vat_value: comptes_comptables[i].vat.vat_value,
                                })
                            }
                        }
                    }
                }

	            this.comptes_most_used = await this.getChargeAccountSupplierMostUsed(this.invoice_supplier.suppliersocr_entity, this.invoice_supplier.suppliersocr_tiers)
	            
                if(this.invoice_supplier.suppliersocr_export_status == 'to_prepare') {
	                // récupération des lignes de TVA
	                const charges = JSON.parse(this.invoice_supplier.suppliersocr_ocr_data).prediction.taxes
	                if(charges) {
	                	// pour chaque TVA je crée une charge
		                for(let i in charges) {
							let account = cloneDeep(this.default_account)

							account.ischargeaccount_amount = charges[i].base ? charges[i].base.toString() : 0
							account.ischargeaccount_vatamount = charges[i].value ? charges[i].value.toString() : ''

							// si j'ai un compte le plus utilisé je le set
							if(this.comptes_most_used) {
								if(this.comptes_most_used.accounting_account)
									account.ischargeaccount_accountingaccount = this.comptes_comptables.find(compte => compte.id == this.comptes_most_used.accounting_account)
							}

							// je récupère le compte de TVA correspondant à ma TVA
							let vat_account = this.comptes_comptables_vat.filter(vat => vat.vat_value*100 == charges[i].rate)
							if(vat_account.length == 1) {
								account.ischargeaccount_vataccount = vat_account[0] 
							}
							else if(vat_account.length > 1) {
								// si j'en ai plusieurs correspondant je prends le plus utilisé
								let vat_id = vat_account[0].vat
								if(this.comptes_most_used['vat_'+vat_id])
									account.ischargeaccount_vataccount = this.comptes_comptables_vat.find(compte => compte.id == this.comptes_most_used['vat_'+vat_id])
							}

							this.charge_accounts.push(account)
		                }
		            }
		            else {
		                this.addChargeAccount()
		                this.charge_accounts[0].ischargeaccount_amount = this.invoice_supplier.suppliersocr_ht.toString()
		                this.charge_accounts[0].ischargeaccount_vatamount = (this.invoice_supplier.suppliersocr_ttc - this.invoice_supplier.suppliersocr_ht).toFixed(2).toString()
		            }
	            }
	            else {
	            	const charges = await this.getChargeAccountSupplier(this.invoice_supplier.suppliersocr_id)
	            	charges.forEach(charge => {
	            		this.charge_accounts.push({
							ischargeaccount_accountingaccount: this.comptes_comptables.find(compte => compte.id == charge.ischargeaccount_accountingaccount),
							ischargeaccount_vataccount: this.comptes_comptables_vat.find(compte => compte.id == charge.ischargeaccount_vataccount),
							ischargeaccount_horse: charge.ischargeaccount_horse,
							ischargeaccount_amount: charge.ischargeaccount_amount.toString(),
							ischargeaccount_vatamount: charge.ischargeaccount_vatamount.toString()
	            		})
	            	})
	            }

                this.loading = false
			},

			addChargeAccount() {
				let account = cloneDeep(this.default_account)
				if(this.comptes_most_used && this.comptes_most_used.accounting_account) {
					account.ischargeaccount_accountingaccount = this.comptes_comptables.find(compte => compte.id == this.comptes_most_used.accounting_account)
				}
                this.charge_accounts.push(account)
			},

			deleteCharge(index) {
				this.charge_accounts.splice(index, 1)
			},

			checkAmount() {
				this.error_sum_amount = this.charge_accounts.reduce((sum, charge) => sum + parseFloat(charge.ischargeaccount_amount.replace(',', '.')), 0) != this.invoice_supplier.suppliersocr_ht
			},

			checkVatAmount() {
				this.error_sum_vat = this.charge_accounts.reduce((sum, charge) => sum + parseFloat(charge.ischargeaccount_vatamount.replace(',', '.')), 0) != (this.invoice_supplier.suppliersocr_ttc - this.invoice_supplier.suppliersocr_ht).toFixed(2)
			},

			async save() {
				const can_save = this.charge_accounts.every(charge => charge.ischargeaccount_accountingaccount && charge.ischargeaccount_vataccount)
				if(!can_save) {
					this.failureToast('formulaire.erreur_champs_non_remplis')
					return false
				}

				this.processing = true
				const charges = this.charge_accounts.map(charge => {
					return {
						ischargeaccount_accountingaccount: charge.ischargeaccount_accountingaccount.id,
						ischargeaccount_vataccount: charge.ischargeaccount_vataccount.id,
						ischargeaccount_horse: charge.ischargeaccount_horse ? charge.ischargeaccount_horse.horse_id : null,
						ischargeaccount_amount: parseFloat(charge.ischargeaccount_amount.replace(',', '.')).toFixed(2) * 100,
						ischargeaccount_vatamount: parseFloat(charge.ischargeaccount_vatamount.replace(',', '.')).toFixed(2) * 100
					}
				})
	            await this.saveChargeAccountSupplier(this.invoice_supplier.suppliersocr_id, charges)
				this.processing = false
				this.ok()
				this.shutterPanel().close('invoice-supplier-charge-account')
			},

			skip() {
				this.$router.push({ name: 'invoiceSupplierChecked' })
			}
		},

		components: {
			SearchHorse : () => import('@/components/Contract/SearchHorse'),
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert')
		}
	}

</script>